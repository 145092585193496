import React from 'react';

const ChatHeader = ({ toggleSidebar, onCreateNewChat }) => {
    return (
        <div className="chat-header">
            <img
                src="menuPic.jpg"
                alt="Toggle Sidebar"
                className="toggle-sidebar-button"
                onClick={toggleSidebar}
            />
            <h1>Bragr AI</h1>
            <button className="new-chat-button" onClick={onCreateNewChat}>+</button>
        </div>
    );
};

export default ChatHeader;
