// App.js

import React, { useState, useEffect, useRef  } from 'react';
import ChatWindow from './components/ChatWindow';
import ChatSidebar from './components/ChatSidebar';
import ChatHeader from './components/ChatHeader';
import './App.css';
import axios from 'axios';

const baseUrl = process.env.REACT_APP_BASE_URL;

const App = ({ user, onLogout }) => {
    const [chats, setChats] = useState([]);
    const [currentChatId, setCurrentChatId] = useState(0); 
    const [sidebarVisible, setSidebarVisible] = useState(true);
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false); 
    const isCreatingChat = useRef(false);
    // const isAuthenticated = user && user.sucess;

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth < 500) {
                setSidebarVisible(false);
            } else {
                setSidebarVisible(true);
            }
        };

        handleResize();
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        // if (isAuthenticated) {
            const fetchChatHistory = async () => {
                setLoading(true);
                try {
                    const response = await axios.get(`${baseUrl}/api/chat-history/`, {
                        withCredentials: true
                    });

                    if (response.data.success) {
                        const chatHistory = response.data.chat_history;
                        setChats(chatHistory);
                        if (chatHistory.length > 0) {
                            setCurrentChatId(chatHistory[0].chat_id);
                        }
                       else {
                        // If no chat history exists, create a new chat
                        handleCreateNewChat();
                    }
                    }
                } catch (error) {
                    console.error('Error fetching chat history:', error);
                    setError('Failed to load chat history. Please try again.');
                } finally {
                    setLoading(false);
                }
            };

            fetchChatHistory();
        // }
    // }, [isAuthenticated]);
    }, []);

    const updateChatMessages = (chatId, newMessage) => {
        setChats(prevChats => {
            const updatedChats = prevChats.map(chat => {
                if (chat.chat_id === chatId) {
                    return {
                        ...chat,
                        messages: [...chat.messages, newMessage]
                    };
                }
                return chat;
            });
            return updatedChats;
        });
    };

    const handleSendMessage = async (message) => {
        const newMessage = { role: 'user', content: message };
        updateChatMessages(currentChatId, newMessage);
        setLoading(true); 
        try {
            const response = await axios.post(`${baseUrl}/api/chat/${currentChatId}/`, {
                message
            }, {
                withCredentials: true});

            const botMessage = { role: 'assistant', content: response.data.response };
            updateChatMessages(currentChatId, botMessage);
        } catch (error) {
            console.error('Error sending message:', error);
            setError('Failed to send message. Please try again.');
        } finally {
            setLoading(false);
        }
    };

    const handleSelectChat = async (chatId) => {
        setCurrentChatId(chatId);
    };

    const handleCreateNewChat = async () => {
      if (isCreatingChat.current) return;
      isCreatingChat.current = true;
        setLoading(true);
        try {
            const response = await axios.post(`${baseUrl}/api/create-chat/`, {}, {
                withCredentials: true
            });
    
            if (response.data.success) {
                const newChatId = response.data.chat_id;
                const newChat = { chat_id: newChatId, messages: [] };
                setChats(prevChats => [newChat, ...prevChats, ]);
                setCurrentChatId(newChatId);
            }
        } catch (error) {
            console.error('Error creating new chat:', error);
            setError('Failed to create a new chat. Please try again.');
        } finally {
            setLoading(false);
            isCreatingChat.current = false;
        }
    };

    const toggleSidebar = () => {
        setSidebarVisible(!sidebarVisible);
    };

    return (
        <div className="App">
            <ChatHeader
                toggleSidebar={toggleSidebar}
                onCreateNewChat={handleCreateNewChat}
            />
            <div className="chat-container">
                {sidebarVisible && (
                    <ChatSidebar
                        chatHistory={chats}
                        onSelectChat={handleSelectChat}
                        onLogout={onLogout}
                    />
                )}
                
                <ChatWindow
                    messages={chats.find(chat => chat.chat_id === currentChatId)?.messages || []}
                    onSendMessage={handleSendMessage}
                />
            </div>
            {loading && <div className="loading-message">Loading...</div>}
            {error && <div className="error-message">{error}</div>}
        </div>
    );
};

export default App;
