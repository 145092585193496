import React from 'react';
import ChatInput from './ChatInput';
import ChatMessage from './ChatMessage';

const ChatWindow = ({ messages, onSendMessage }) => {
    console.log("CAT WINDOW",messages)
    return (
        <div className="chat-window">
            <div className="messages">
                {messages.map((msg, index) => (
                    <ChatMessage key={index} message={msg.content} role={msg.role} />
                ))}
            </div>
            <ChatInput onSendMessage={onSendMessage} />
        </div>
    );
};

export default ChatWindow;
