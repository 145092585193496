import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import '../App.css';

const baseUrl = process.env.REACT_APP_BASE_URL;
const Register = ({ onRegister }) => {
    const [formData, setFormData] = useState({
        username: '',
        first_name: '',
        last_name: '',
        email: '',
        password1: '',
        password2: ''
    });
    const [error, setError] = useState('');
    const navigate = useNavigate();

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            const response = await axios.post(
                `${baseUrl}/api/register/`,
                JSON.stringify(formData),
                {
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    withCredentials: true  // Include credentials to handle cookies
                }
            );

            if (response.data.success) {
                alert('Registration successful! Redirecting to login page.');
                onRegister(response.data);
                navigate('/login');
            } else {
                alert('Registration failed. Please check your inputs.');
                console.log(response.data.errors);
                setError(response.data.errors.join(', '));  // Display error messages
            }
        } catch (error) {
            console.error('Error during registration:', error);
            setError('An error occurred. Please try again.');
        }
    };

    return (
        <div className="auth-container">
            <div className="auth-form">
                <img src="/logo192.jpg" alt="Company Logo" className="auth-logo" />
                <h1>Register</h1>
                <form onSubmit={handleSubmit}>
                    <div>
                        <label>Username:</label>
                        <input type="text" name="username" value={formData.username} onChange={handleChange} />
                    </div>
                    <div>
                        <label>First Name:</label>
                        <input type="text" name="first_name" value={formData.first_name} onChange={handleChange} />
                    </div>
                    <div>
                        <label>Last Name:</label>
                        <input type="text" name="last_name" value={formData.last_name} onChange={handleChange} />
                    </div>
                    <div>
                        <label>Email:</label>
                        <input type="email" name="email" value={formData.email} onChange={handleChange} />
                    </div>
                    <div>
                        <label>Password:</label>
                        <input type="password" name="password1" value={formData.password1} onChange={handleChange} />
                    </div>
                    <div>
                        <label>Confirm Password:</label>
                        <input type="password" name="password2" value={formData.password2} onChange={handleChange} />
                    </div>
                    <button type="submit">Register</button>
                    {error && <p className="error">{error}</p>}
                </form>
                <button onClick={() => navigate('/login')} className="switch-button">Login</button>
            </div>
        </div>
    );
};

export default Register;
