import React, { useReducer, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import axios from 'axios';
import Login from './components/Login';
import Register from './components/Register';
import App from './App';
import './App.css';

const baseUrl = process.env.REACT_APP_BASE_URL;

const authReducer = (state, action) => {
    console.log('Reducer action:', action); // Log the action being dispatched
    switch (action.type) {
        case 'LOGIN':
            console.log('Logging in user:', action.payload); // Log user data on login
            localStorage.setItem('user', JSON.stringify(action.payload));
            return { ...state, user: action.payload };
        case 'LOGOUT':
            console.log('Logging out user'); // Log logout action
            localStorage.removeItem('user');
            return { ...state, user: null };
        default:
            return state;
    }
};

const initialState = {
    user: JSON.parse(localStorage.getItem('user')) || null,
};

const Auth = () => {
    const [state, dispatch] = useReducer(authReducer, initialState);

    useEffect(() => {
        const checkAuth = async () => {
            console.log('Checking authentication status...');
            try {
                const response = await axios.get(`${baseUrl}/api/check-auth/`);
                console.log('Authentication check response:', response.data); // Log the response from the server
                if (response.data.isAuthenticated) {
                    console.log('User is authenticated:', response.data.user); // Log authenticated user data
                    dispatch({ type: 'LOGIN', payload: response.data.user });
                } else {
                    console.log('User is not authenticated');
                }
            } catch (error) {
                console.error('Error checking authentication', error);
            }
        };

        if (!state.user) {
            checkAuth();
        }
    }, [state.user]);

    const handleLogin = (userData) => {
        console.log('Handling login with data:', userData); // Log login data
        dispatch({ type: 'LOGIN', payload: userData });
    };

    const handleRegister = (userData) => {
        console.log('Handling registration with data:', userData); // Log registration data
        dispatch({ type: 'LOGIN', payload: userData });
    };

    const handleLogout = async () => {
        try {
            console.log('Handling logout');
            await axios.post(`${baseUrl}/api/logout/`, {}, { withCredentials: true });
            dispatch({ type: 'LOGOUT' });
            localStorage.removeItem('user');
        } catch (error) {
            console.error('Failed to logout:', error);
        }
    };    

    return (
        <Router>
            <Routes>
                <Route path="/login" element={<Login onLogin={handleLogin} />} />
                <Route path="/register" element={<Register onRegister={handleRegister} />} />
                {state.user ? (
                    <Route path="/" element={<App user={state.user} onLogout={handleLogout} />} />
                ) : (
                    <Route path="/" element={<Navigate to="/login" />} />
                )}
            </Routes>
        </Router>
    );
};

export default Auth;
