import React, { useState } from 'react';

const ChatSidebar = ({ chatHistory, onSelectChat, onLogout }) => {
    const [modalContent, setModalContent] = useState(null);

    const handleButtonClick = (content) => {
        setModalContent(content);
    };

    const closeModal = () => {
        setModalContent(null);
    };

    const performLogout = () => {
        if (typeof onLogout === 'function') {
            console.log('Logging out...');
            onLogout();
            closeModal();
        } else {
            console.error('onLogout is not defined as a function');
        }
    };

    return (
        <div className="chat-sidebar">
            <h3 className="chat-history-title">Chat History</h3>
            <div className="chat-history">
                {chatHistory.map((chat, index) => (
                    <div 
                        key={chat.chat_id} 
                        className="chat-history-item"
                        onClick={() => onSelectChat(chat.chat_id)}
                        >
                            Chat {index+1}
                    </div>
                ))}
            </div>

            <div className="sidebar-footer">
                <button onClick={() => handleButtonClick('account-settings')}>Account Settings</button>
                <hr />
                <button onClick={() => handleButtonClick('terms-of-use')}>Terms of Use</button>
                <hr />
                <button onClick={() => handleButtonClick('privacy-policy')}>Privacy Policy</button>
                <hr />
                <button onClick={() => handleButtonClick('contact-us')}>Contact Us</button>
                <hr />
                <button onClick={() => handleButtonClick('log-out')}>Log Out</button>
            </div>

            {modalContent && (
                <div className="modal-overlay">
                    <div className="modal-content">
                        <button className="close-button" onClick={closeModal}>X</button>
                        {modalContent === 'account-settings' && (
                            <div>
                                <h2>Account Settings</h2>
                                <form className="account-settings-form">
                                    <div className="form-group">
                                        <label>Email Address</label>
                                        <input type="email" value="user@example.com" disabled />
                                    </div>
                                    <div className="form-group">
                                        <label>Current Password</label>
                                        <input type="password" />
                                    </div>
                                    <div className="form-group">
                                        <label>New Password</label>
                                        <input type="password" />
                                    </div>
                                    <div className="form-group">
                                        <label>Confirm New Password</label>
                                        <input type="password" />
                                    </div>
                                    <button type="submit">Save New Password</button>
                                </form>
                                <p>Password must be at least 10 characters and contain:</p>
                                <ul>
                                    <li>Letters and numbers only</li>
                                    <li>At least one capital letter</li>
                                    <li>At least one number</li>
                                </ul>
                            </div>
                        )}
                        {modalContent === 'terms-of-use' && (
                            <div>
                                <h2>Terms of Use</h2>
                                <p>These are the terms of use for the application. By using this app, you agree to the following terms...</p>
                            </div>
                        )}
                        {modalContent === 'privacy-policy' && (
                            <div>
                                <h2>Privacy Policy</h2>
                                <p>Your privacy is important to us. This privacy policy explains how we collect, use, and protect your information...</p>
                            </div>
                        )}
                        {modalContent === 'contact-us' && (
                            <div>
                                <h2>Contact Us</h2>
                                <p>If you have any questions or need support, please contact us at ...</p>
                            </div>
                        )}
                        {modalContent === 'log-out' && (
                            <div>
                                <h2>Log Out</h2>
                                <p>Previous chat histories are only available to logged in users. You may lose any chat history upon logging out.</p>
                                <button onClick={performLogout}>Confirm Log Out</button>
                            </div>
                        )}
                    </div>
                </div>
            )}
        </div>
    );
};

export default ChatSidebar;
