import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import '../App.css';

const baseUrl = process.env.REACT_APP_BASE_URL;

const Login = ({ onLogin }) => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        const isConfirmed = window.confirm(
            "Warning: This application may provide information related to sports gambling. Please confirm that you are 18 years of age or older to continue."
        );
        if (!isConfirmed) {
            navigate('Sorry, you must be over 18 years old to visit this website');
        }
    }, [navigate]);

    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            console.log('Logging in with - ', baseUrl);
            const response = await axios.post(
                `${baseUrl}/api/login/`,
                { username, password },
                { withCredentials: true }  // Important for including cookies in requests
            );

            if (response.data.success) {
                onLogin(response.data);
                navigate('/');
            } else {
                setError('Invalid username or password');
            }
        } catch (error) {
            setError('An error occurred. Please try again.');
        }
    };

    return (
        <div className="auth-container">
            <div className="auth-form">
                <img src="/logo192.jpg" alt="Company Logo" className="auth-logo" />
                <h1>Login</h1>
                <form onSubmit={handleSubmit}>
                    <div>
                        <label>Username:</label>
                        <input type="text" value={username} onChange={(e) => setUsername(e.target.value)} />
                    </div>
                    <div>
                        <label>Password:</label>
                        <input type="password" value={password} onChange={(e) => setPassword(e.target.value)} />
                    </div>
                    <button type="submit">Login</button>
                    {error && <p className="error">{error}</p>}
                </form>
                <button onClick={() => navigate('/register')} className="switch-button">Register</button>
            </div>
        </div>
    );
};

export default Login;
